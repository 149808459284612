@use '@angular/material' as mat;
@import "@angular/material/theming";

$mat-techmart-orange: (
  50 : #fef2e4,
  100 : #fddfbb,
  200 : #fbca8e,
  300 : #f9b561,
  400 : #ffa906,
  500 : #f7951d,
  600 : #f68d1a,
  700 : #f58215,
  800 : #f37811,
  900 : #f1670a,
  A100 : #ffffff,
  A200 : #fff0e7,
  A400 : #ffcfb4,
  A700 : #ffbe9b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$mat-magnum-blue: (
  50 : #a1b5ff,
  100 : #88a2ff,
  200 : #7a97ff,
  300 : #6788ff,
  400 : #5d80ff,
  500 : #577bfd,
  600 : #4970ff,
  700 : #3d66fc,
  800 : #2e5cff,
  900 : #2151fd,
  A100 : #ffffff,
  A200 : #fff0e7,
  A400 : #ffcfb4,
  A700 : #ffbe9b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$mat-techmart-grey: (
  25 : #fafafa,
  50 : #f4f4f4,
  100 : #bebfc2,
  200 : #93959a,
  300 : #686b71,
  400 : #474b52,
  500 : #272b34,
  600 : #23262f,
  700 : #1d2027,
  800 : #171a21,
  900 : #0e1015,
  A100 : #599cff,
  A200 : #267dff,
  A400 : #0061f2,
  A700 : #2151FD,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($mat-magnum-blue, 800, 500, 900);
$app-accent:  mat.define-palette($mat-techmart-grey, 600, 500, 700);
// The warn palette is optional (defaults to red).
$app-warn:    mat.define-palette(mat.$red-palette);

// disabled text color
$dark-disabled-text: rgba(black, 0.6);
// Foreground palette for light themes.
mat.$light-theme-foreground-palette: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba(black, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  hint-text:         $dark-disabled-text,
  secondary-text:    $dark-secondary-text,
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);